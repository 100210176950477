<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("materials.edit.title") }}</h3>
        <h3 v-else>{{ $t("materials.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- Name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('materials.labels.name')"
                maxlength="200"
                hide-details
                v-model="form.name"
              />
            </v-col>

            <!-- FM Referent -->
            <v-col cols="12">
              <SelectFMReferent
                :value="form.fm_referents"
                :is-multiple="true"
                @input="onInputFMReferents"
              />
            </v-col>

            <!-- Supplier -->
            <v-col cols="12">
              <SelectSupplier
                :value="form.supplier"
                :return-object="true"
                :is-clearable="true"
                @input="onInputSupplier"
              />
            </v-col>

            <!-- Raw Material -->
            <v-col cols="12">
              <SelectRawMaterial
                :value="form.raw_material"
                :is-required="true"
                :is-clearable="false"
                @input="onInputRawMaterial"
              />
            </v-col>
            <v-col cols="12">
              <AlertBar />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "MaterialMainDialog",

  mixins: [dialogMixin, formRules],

  props: {
    supplierId: { type: String, default: null },
  },

  components: {
    SelectRawMaterial: () =>
      import("@/components/Common/Inputs/SelectRawMaterial"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
    SelectFMReferent: () =>
      import("@/components/Common/Inputs/SelectFMReferent"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier"),
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        name: "",
        fm_referents: [
          { id: this.$session.get("id"), person: this.$session.get("person") },
        ],
        // supplier: this.item? this.item.supplier.id : this.supplierId,
        supplier: null,
        raw_material: null,
      },
    };
  },

  created() {
    // console.log(this.item.supplier)
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["fm_referents", "supplier"]);
        this.shortcutFormObjects(["raw_material"], "value");

        if (this.item) this.submitEdit();
        else this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .post(`/materials`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("materials.add.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    submitEdit() {
      this.$http
        .put(`/materials/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("materials.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputFMReferents(val) {
      this.form.fm_referents = val;
    },

    onInputSupplier(val) {
      this.form.supplier = val;
    },

    onInputRawMaterial(val) {
      this.form.raw_material = val;
    },
  },

  computed: {},
};
</script>

<style scoped></style>
